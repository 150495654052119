import React, { Fragment, useEffect } from 'react'
import { getClientId } from '../../helpers/getClientId'
import ZoominfoFields from './Zoominfo-fields'
import callZoominfo from '../../helpers/zoomInfo'

const webinarRegistrationForm = () => {
    const setStorage = () => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('webinarRegistered', true)
        }
    }

    useEffect(() => {
        // Zoominfo Snippet
        callZoominfo('uoOiaHNN8N3QdXczOrJm')
        // Getting clientId
        const interval = setInterval(function () {
            if (getClientId()) {
                let gaClientId = getClientId()
                document.querySelector('#ga_client_id').value = gaClientId
                clearInterval(interval)
            }
        }, 1000)

        // es-lint-disable-next-line
    }, [])

    return (
        <Fragment>
            <h2
                style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    lineHeight: '22px',
                    paddingBottom: '20px',
                    paddingLeft: '15px',
                }}>
                Submit the form below to watch the webinar.
            </h2>

            {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
            <form
                id='zoominfo-all-fields-form'
                action='https://go.pardot.com/l/877242/2020-08-03/4fkc5f'
                method='POST'
                onSubmit={setStorage}>
                <ZoominfoFields includeCompany={true} includePhone={true} includeJobTitle={true} />

                <table
                    className='ml-2'
                    style={{
                        width: '300px',
                        color: '#666677',
                    }}>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className='form-control w-100 my-2'
                                    type='text'
                                    maxLength='80'
                                    style={{ width: '250px' }}
                                    name='First Name'
                                    placeholder='First Name*'
                                    required></input>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input
                                    className='form-control w-100 my-2'
                                    type='text'
                                    maxLength='80'
                                    style={{ width: '250px' }}
                                    name='Last Name'
                                    placeholder='Last Name*'
                                    required></input>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input
                                    className='form-control w-100 my-2'
                                    type='email'
                                    maxLength='100'
                                    style={{ width: '250px' }}
                                    name='Email'
                                    placeholder='Email*'
                                    required></input>
                            </td>
                        </tr>

                        <input
                            name='GA Client ID'
                            type='hidden'
                            className='d-none'
                            id='ga_client_id'
                        />

                        <tr>
                            <td>
                                <div className='m-1'>
                                    <label className='d-none' htmlFor='Terms Of Use'>
                                        {' '}
                                        Terms of use and Privacy Policy
                                    </label>
                                    <input type='checkbox' name='Terms Of Use' required />{' '}
                                    <span className='pl-1'>
                                        By submitting the form, you agree to our{' '}
                                        <a
                                            className='link-text'
                                            href='/legals/terms-and-conditions/'>
                                            Terms of Use{' '}
                                        </a>
                                        and{' '}
                                        <a className='link-text' href='/legals/policy/'>
                                            Privacy Policy
                                        </a>
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan='2' className='pt-2 f-14 text-left'>
                                <input
                                    className='btn btn-second btn-block'
                                    type='submit'
                                    value='Submit'
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </Fragment>
    )
}

export default webinarRegistrationForm
